<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <form class="g-3 form-import-bulk-update-new" novalidate>
        <div v-if="errors.length > 0" class="alert alert-warning">
          <ul class="mb-0">
            <li v-for="error in errors" v-bind:key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <div v-if="isSuccess" class="alert alert-success">
          {{
            $t(
              "Messages.SuccessMessage",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
        <div
          class="row row-flex-cards"
          :class="{
            'd-flex justify-content-center align-items-center mt-3':
              String.isNullOrWhiteSpace(importData.customObjectPublicId) ||
              String.isNullOrWhiteSpace(importData.processTypeId),
          }"
        >
          <div class="col-md-6">
            <div class="card border-primary">
              <div
                class="card-header bg-primary text-white align-middle text-left p-3"
              >
                <i class="bi bi-gear"></i>
                {{
                  $t(
                    "BaseModelFields.GeneralSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
                <span
                  class="text-warning"
                  v-if="
                    String.isNullOrWhiteSpace(
                      importData.customObjectPublicId
                    ) || String.isNullOrWhiteSpace(importData.processTypeId)
                  "
                >
                  (<u>
                    {{
                      $t(
                        "FieldErrors.FillInTheRequiredFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</u
                  >)</span
                >
              </div>
              <div class="card-body text-primary">
                <div class="row row-cols-1 mt-3 mb-3">
                  <div class="col col-md-6">
                    <label for="CustomObject" class="form-label required"
                      >{{
                        $t(
                          "BaseModelFields.CustomObject",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <FormSelect
                      type="SelectWithLocalData"
                      :isDetailIconShow="true"
                      :routeLink="String.format('#/CustomObject/Detail/')"
                      @onChange="onChangeCustomObject"
                      :allowEmpty="false"
                      :data="customObjects"
                      :isDisabled="selectedFiles.length > 0"
                    />
                  </div>
                  <div class="col col-md-6">
                    <label for="RecordUploadType" class="form-label required">{{
                      $t(
                        "ImportBulkRecordData.RecordUploadType",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label>
                    <FormSelect
                      type="SelectWithLocalData"
                      :data="typeData"
                      :allowEmpty="false"
                      @onChange="onChangeDataImportProcessType"
                    />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col col-md-10">
                    <label for="Description" class="form-label"
                      >{{
                        $t(
                          "DeletionRule.Description",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <textarea
                      class="form-control"
                      id="Description"
                      rows="2"
                      v-model="importData.description"
                      :spellcheck="this.$isTextSpellCheck"
                    ></textarea>
                  </div>
                  <div class="col col-md-2">
                    <label for="IsActive" class="form-label"
                      >{{
                        $t(
                          "BaseModelFields.IsActive",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="IsActive"
                        v-model="importData.isActive"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6"
            v-if="
              !String.isNullOrWhiteSpace(importData.customObjectPublicId) &&
              !String.isNullOrWhiteSpace(importData.processTypeId)
            "
          >
            <div class="card border-warning">
              <div
                class="card-header bg-warning text-white align-middle text-left p-3"
              >
                <i class="bi bi-sliders"></i>
                {{
                  $t(
                    "BaseModelFields.TriggerSettings",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
              <div class="card-body text-primary">
                <div class="row row-cols-1 mt-3 mb-3">
                  <div class="col-md-6">
                    <div class="form-check">
                      <label
                        for="DoNotCalculateRollupFields"
                        class="form-label"
                        >{{
                          $t(
                            "ImportBulkRecordData.DoNotCalculateRollupFields",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="DoNotCalculateRollupFields"
                        v-model="importData.doNotCalculateRollupFields"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <label
                        for="DoNotCalculateFormulaFields"
                        class="form-label"
                        >{{
                          $t(
                            "ImportBulkRecordData.DoNotCalculateFormulaFields",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="DoNotCalculateFormulaFields"
                        v-model="importData.doNotCalculateFormulaFields"
                      />
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 mt-3 mb-3">
                  <div class="col-md-6">
                    <div class="form-check">
                      <label
                        for="DoNotTriggerBusinessRule"
                        class="form-label"
                        >{{
                          $t(
                            "ImportBulkRecordData.DoNotTriggerBusinessRule",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="DoNotTriggerBusinessRule"
                        v-model="importData.doNotTriggerBusinessRule"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <label for="InstantStart" class="form-label">{{
                        $t(
                          "ImportBulkRecordData.InstantStart",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="InstantStart"
                        @change="instantStartOnChange"
                        v-model="importData.instantStart"
                      />
                    </div>
                  </div>
                </div>
                <div class="row row-cols-1 mt-3 mb-3">
                  <div class="col col-md-6">
                    <label for="Email" class="form-label"
                      >{{
                        $t(
                          "ImportBulkRecordData.Email",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="Email"
                      v-model="importData.email"
                    />
                  </div>
                  <div class="col col-md-6" v-if="!importData.instantStart">
                    <label
                      for=""
                      class="form-label"
                      :class="{ required: !importData.instantStart }"
                      >{{
                        $t(
                          "RecordLogs.StartDate",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                    <DatePicker
                      ref="startDate"
                      type="datetime"
                      :value="startDate"
                      :isRequired="!importData.instantStart"
                      :isDisabled="importData.instantStart"
                      :displayFormat="$store.getters._dateTimeFormat"
                      @valueChanged="onValueChangedStartDate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="
            !String.isNullOrWhiteSpace(importData.customObjectPublicId) &&
            !String.isNullOrWhiteSpace(importData.processTypeId)
          "
        >
          <div class="col-md-12">
            <div class="card border-success">
              <div
                class="card-header bg-success text-white align-middle text-left p-3"
              >
                <label for="" class="required">
                  <i class="bi bi-file-earmark-spreadsheet"></i>
                  {{
                    $t(
                      "ImportBulkRecordData.ImportFile",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
              </div>
              <div class="card-body text-primary">
                <template v-if="selectedFiles.length == 0">
                  <div
                    class="p-4 mt-3 bg-gray-100 file-drag-and-drop-block"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                  >
                    <input
                      type="file"
                      id="File"
                      @click="onFileInputClick"
                      @change="onFileSelected"
                      ref="fileInput"
                      class="hidden"
                      accept=".xlsx, .xls"
                    />

                    <label for="File" class="block cursor-pointer w-100 fs-5">
                      <div class="text-center fw-bold">
                        {{
                          $t(
                            "FormTemplates.BrowseOrDragandDrop",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </div>
                    </label>
                  </div>
                </template>
                <template v-else>
                  <ul class="list-group mt-3 border-success border">
                    <li
                      class="list-group-item p-1 d-flex justify-content-between align-items-center"
                      v-for="file in selectedFiles"
                      :key="file"
                    >
                      <span>
                        {{ file.name }}
                        <span class="ms-1 badge badge-primary">
                          {{ file.size }} kb</span
                        >
                      </span>
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#modalMessage"
                        class="cursor-pointer badge badge-danger align-middle float-end"
                      >
                        <i class="bi bi-x fs-5"></i>
                      </a>
                    </li>
                  </ul>
                  <div class="card border-info mt-3 mb-0">
                    <div
                      class="card-header bg-info text-white align-middle text-left p-2"
                    >
                      <i class="bi bi bi-arrow-left-right"></i>
                      {{
                        $t(
                          "ImportBulkRecordData.MappingTable",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </div>
                    <div
                      class="card-body text-primary p-1 mh-130"
                      id="mappingCardBody"
                    >
                      <DxLoadPanel
                        v-if="!isMappingFinish"
                        :position="{ of: '#mappingCardBody' }"
                        :visible="true"
                        :show-indicator="true"
                        :show-pane="true"
                        :shading="true"
                        :hide-on-outside-click="false"
                        shading-color="rgba(0,0,0,0.4)"
                      />
                      <MappingTable
                        :excelFields="excelFields"
                        :objectFields="customObjectFields"
                        :customObjectAllFields="customObjectAllFields"
                        :customObjectMappingFields="customObjectMappingFields"
                        @exportMapping="exportMapping"
                        v-else
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <ActionButtons
          v-if="
            !String.isNullOrWhiteSpace(importData.customObjectPublicId) &&
            !String.isNullOrWhiteSpace(importData.processTypeId) &&
            selectedFiles.length > 0
          "
          @methodSave="onSubmit('btn-save')"
          :isNewRecord="false"
        />
      </form>
    </div>
  </div>
  <AlertModal
    messageKey="ImportBulkRecordData.ReloadMapping"
    @yes="reloadMappingYes"
    @no="reloadMappingNo"
  />
</template>
<script>
import readXlsxFile from "read-excel-file";
import { createToast } from "mosha-vue-toastify";

import $ from "jquery";

import { DxLoadPanel } from "devextreme-vue/load-panel";
import AlertModal from "@//components/custom/alert/AlertModal.vue";
import MappingTable from "@/components/page-partials/data-import/MappingTable.vue";
export default {
  name: "ImportBulkRecordDataNew",
  components: {
    AlertModal,
    DxLoadPanel,
    MappingTable,
  },
  data() {
    return {
      isMappingFinish: false,
      excelFields: [],
      excelKeyRegexExps: [null, undefined, "null", "undefined"],
      customObjectAllFields: [],
      customObjectFields: [],
      customObjectMappingFields: [],
      selectedFiles: [],
      notUploadedFiles: [],
      startDate: null,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      importData: {
        isActive: true,
        email: this.$store.getters._currentUserEmailAddress,
        fieldMapping: [],
      },
      isSuccess: false,
      selectValueReset: false,
      typeData: [
        {
          key: "1",
          value: this.$t(
            "ImportBulkRecordData.Insert",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "ImportBulkRecordData.Update",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      customObjects: [],
    };
  },
  mounted() {
    this.getCustomObjects();
  },
  watch: {
    selectedFiles() {
      var self = this;
      this.isMappingFinish = false;
      this.excelFields = [];
      if (this.selectedFiles.length > 0) {
        readXlsxFile(this.selectedFiles[0])
          .then((rows) => {
            var columns = rows[0];
            columns.forEach((column, i) => {
              self.excelFields.push({ key: i, value: column });
            });
          })
          .finally(() => {
            if (
              self.excelFields.length == 0 ||
              (self.excelFields.length > 0 &&
                self.excelFields.find((f) =>
                  self.excelKeyRegexExps.includes(f.value)
                ))
            ) {
              self.selectedFiles = [];
              createToast(
                this.$t(
                  "FieldErrors.ExcelColumnsEmptyOrWrongDataType",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: "true",
                  position: "top-right",
                  type: "danger",
                  transition: "zoom",
                }
              );
            } else {
              self.getFields();
            }
          });
      }
    },
  },
  methods: {
    getCustomObjects() {
      this.$prodGatewayAxios
        .get("/Lcdp-CustomObjectList?size=1000")
        .then((response) => {
          this.customObjects = response.data.items.map(function (m) {
            return {
              key: m.publicId,
              value: m.name,
              customObjectKey: m.key,
            };
          });
        });
    },
    exportMapping(model) {
      this.importData.fieldMapping = model;
    },
    onFileInputClick(e) {
      if (this.selectedFiles.length > 0) {
        $("#message-modal").trigger("click");
        e.preventDefault();
      }
    },
    onFileSelected() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      if (!this.extensionControl(this.selectedFiles[0].name)) {
        return;
      }
      this.errors = [];
      this.importData.file = this.selectedFiles[0];
      this.importData.fileName = this.selectedFiles[0].name;
      this.importData.filePath =
        "." + this.selectedFiles[0].name.split(".").pop();
    },
    dragAndDropOnChange() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      this.importData.file = this.selectedFiles[0];
      this.importData.fileName = this.selectedFiles[0].name;
      this.importData.filePath =
        "." + this.selectedFiles[0].name.split(".").pop();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
      if (!this.extensionControl(event.dataTransfer.files[0].name)) {
        return;
      }
      this.dragAndDropOnChange(); // Trigger the onChange event manually
    },
    extensionControl(name) {
      if (!name.includes(".xlsx") || !name.includes(".xls")) {
        createToast(
          this.$t(
            "FieldErrors.UploadFileNotSupportedForExcel",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        this.selectedFiles = [];
        return false;
      }
      return true;
    },
    reloadMappingYes() {
      this.selectedFiles = [];
      this.importData.file = null;
      this.importData.fileName = null;
      this.importData.filePath = null;
      // document.getElementById("File").click();
    },
    reloadMappingNo() {
      //
    },
    getFields() {
      this.customObjectAllFields = [];
      this.customObjectFields = [];

      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-FieldList?customObjectPublicId={0}",
            this.importData.customObjectPublicId
          )
        )
        .then((response) => {
          if (this.importData.processTypeId == "2") {
            var publicIdField = {
              key: "PUBLICID",
              value: "PUBLICID",
              fieldType: "SystemEditField",
              organizationalUnitName: null,
              lookupObjectPublicId: null,
              isRequired: true,
              formulaName: "PUBLICID",
            };

            this.customObjectFields.push(publicIdField);
            this.customObjectAllFields.push(publicIdField);
            response.data.items.forEach((field) => {
              this.customObjectAllFields.push(field);
            });
          } else {
            this.customObjectAllFields = response.data.items;
          }

          response.data.items
            .filter(function (v) {
              return (
                !v.fieldType.includes("Formula") &&
                !v.fieldType.includes("Rollup") &&
                v.fieldType !== "AutoId" &&
                v.fieldType !== "Table" &&
                v.fieldType !== "Encrypted" &&
                v.fieldType !== "Html" &&
                v.isActive &&
                !v.isDeleted
              );
            })
            .forEach((field) => {
              this.customObjectFields.push({
                key: field.publicId,
                value: field.name,
                fieldType: field.fieldType,
                organizationalUnitName: field.organizationalUnitName,
                lookupObjectPublicId: field.lookupObjectPublicId,
                isRequired: field.isRequired,
                formulaName: field.formulaName,
              });
            });

          this.automaticFieldMapAndDrawTable();
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    automaticFieldMapAndDrawTable() {
      this.isMappingFinish = true;
      this.customObjectMappingFields = [];
      setTimeout(() => {
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      }, 100);

      var sortingFields = this.customObjectFields.sort(
          (a, b) => b.isRequired - a.isRequired
        ),
        index = 0;
      sortingFields.forEach((field, i) => {
        var currentFieldContainExcelField = this.excelFields.find(
          (f) => f.value == field.formulaName
        );

        var selectedReferenceType = null;
        if (
          field.fieldType == "OrganizationalUnit" ||
          field.fieldType == "SelectList" ||
          field.fieldType == "Predefined" ||
          field.fieldType == "Image" ||
          field.fieldType == "File"
        ) {
          selectedReferenceType = {
            key: 1,
            value: "Public Id",
          };
        } else if (field.fieldType == "Lookup") {
          selectedReferenceType = {
            key: 3,
            value: "Record Public Id",
          };
        }

        if (currentFieldContainExcelField) {
          this.customObjectMappingFields.push({
            rId: String.newGuid(),
            index: ++index,
            field: field,
            excelField: currentFieldContainExcelField,
            referenceFields: [],
            referenceType: selectedReferenceType,
            referenceField: null,
            isDeleteButtonActive: false,
          });
        } else if (field.isRequired) {
          this.customObjectMappingFields.push({
            rId: String.newGuid(),
            index: ++index,
            field: field,
            excelField: null,
            referenceFields: [],
            referenceType: selectedReferenceType,
            referenceField: null,
            isDeleteButtonActive: false,
          });
        }
      });
    },
    instantStartOnChange() {
      this.startDate = null;
      // if (this.importData.instantStart) {
      //   this.startDate = new Date();
      // } else {
      //   this.startDate = null;
      // }
    },
    onChangeCustomObject: function (selected) {
      this.importData.customObjectPublicId = selected.key;
      this.importData.customObjectKey = selected.customObjectKey;
      this.selectValueReset = !this.selectValueReset;
    },
    onChangeDataImportProcessType: function (selected) {
      this.importData.processTypeId = selected.key;
      this.importData.processTypeName = selected.value;
    },
    onValueChangedStartDate(value) {
      this.importData.startAt = value;
      if (!String.isNullOrWhiteSpace(value)) {
        this.importData.startAt = this.$moment(value).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }
    },
    mappingModelUniqueControl() {
      if (
        String.isNullOrWhiteSpace(this.importData.fieldMapping) ||
        this.importData.fieldMapping.length == 0
      ) {
        createToast(
          this.$t(
            "ImportBulkRecordData.NoMatchField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        return false;
      }

      var uniqueMappingTableByExcelColumn = this.importData.fieldMapping.map(
        function (obj) {
          return obj.columnHeaderText;
        }
      );
      uniqueMappingTableByExcelColumn = uniqueMappingTableByExcelColumn.filter(
        function (v, i) {
          return uniqueMappingTableByExcelColumn.indexOf(v) == i;
        }
      );

      return (
        uniqueMappingTableByExcelColumn.length ==
        this.importData.fieldMapping.length
      );
    },
    onSubmit(buttonName) {
      var form = $(".form-import-bulk-update-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      } else if (!this.mappingModelUniqueControl()) {
        if (this.importData.fieldMapping.length > 0) {
          createToast(
            this.$t(
              "ImportBulkRecordData.NoUniqueItems",
              {},
              { locale: this.$store.state.activeLang }
            ),
            {
              showIcon: "true",
              position: "top-right",
              type: "warning",
              transition: "zoom",
            }
          );
        }
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      var formData = new FormData(),
        model = this.importData;
      Object.keys(model).forEach(function (key) {
        if (Array.isArray(model[key])) {
          formData.append(key, JSON.stringify(model[key]));
        } else {
          formData.append(key, model[key]);
        }
      });

      this.$prodGatewayAxios
        .post("/Lcdp-BulkDataImportImport", formData)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/ImportBulkRecordData/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
